<template>
  <div>
    <loader v-show="loading"></loader>
    <div v-show="!loading">
      <iframe :src="url" frameborder="0" v-if="url.length > 0" @load="onMyFrameLoad()"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "qiwi",
  props: {
    dealObj:{
        type:Object,
        default() {
          return {
            DlCode: '00',
            CurPay: 0,
          }
        },
      },
  },
  data() {
    return {
      url: '',
      loading: true,
    }
  },
  components: {
    loader: () => import('@/components/other/loader'),
  },
  computed: {
    amountCheckComputed() {
      return parseInt(this.dealObj.CurPay, 10) > 0;
    },
    demandCheckComputed() {
      return parseInt(this.dealObj.DlCode, 10) > 0 && this.dealObj.DlCode.length === 8;
    },
  },
  watch: {
    'dealObj.CurPay': {
      handler() {
        this.createBill()
      }
    }
  },
  methods: {
    onMyFrameLoad() {
      this.loading = false;
    },
    checkValidDeal() {
      return new Promise((resolve, reject) => {
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'validDeal',
          DemandID: this.dealObj.DlCode
        })
          .then((res) => {
            resolve(res);
          })
          .catch((res) => {
            if (res.errorCode > 0 || res.errorCode !== undefined) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
            reject(res);
          });
      });
    },
    createBill() {
      this.$myApi.singleRequest({
        action: 'createBill',
        type: 'Qiwi',
        demand: this.dealObj.DlCode,
        amount: this.dealObj.CurPay
      }).then((res) => {
          this.url = res.payUrl;
      }).catch((res) => {
        let result = this.$myApi.parseError(res);
        alert(result.ExecutionErrorMessage);
        this.loading = false;
      })
    },

  },
  mounted() {
    if (this.demandCheckComputed && this.amountCheckComputed) {
      this.checkValidDeal()
        .then((res) => {
          console.log(res);
          this.createBill();
        })
        .catch((res) => {
          let result = this.$myApi.parseError(res);
          alert(result.ExecutionErrorMessage);
          this.laoding = false;
        });

    }
  }
}
</script>

<style scoped>
iframe{width: 100%;height: 550px}

</style>
